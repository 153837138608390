import React, { useEffect, useState } from 'react'
import Typewriter from 'typewriter-effect';
import gpeapi from '../apis/gpeapi';


const Homemain = () => {
  const [catedata,setcatedata] = useState([])
  useEffect(()=>{
    gpeapi.get('/getcategory').then((res)=>setcatedata(res.data.offerlist.map((val)=>val.name)))
  },[])

  const [data,setdata] = useState([])

    useEffect(()=>{
      gpeapi.get('/getbasicdet').then((res)=>setdata(res.data.signupamt)).catch((e)=>console.log('error'))
    },[])
  
    const handleopenurl = ()=>{
      window.open(data?.appurl)
    }

  return (

    <section class="section hero" id="home" aria-label="hero">
      <div class="container">

        <div class="hero-content">

          {/* <p class="hero-subtitle">GwalaxMedia Marketing Agency</p> */}

          <h1  class="h1 hero-title myh1">Are you looking for a Nearby
            <span style={{color:'#acde57',marginTop:20}}> <Typewriter

              options={{
                strings: catedata,
                autoStart: true,
                loop: true,
                delay:'natural',
                
                
              
              }}
            /></span>
          </h1>

          <p class="hero-text">
          Download the App and find Nearby Verified Professionals...
          </p>

          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <a target='_blank' onClick={handleopenurl} class="btn "><img style={{ width: 200 }} src='https://uploads-ssl.webflow.com/60be6e42ecea08f815b762c3/60dea2276223f66d84a60828_google-app-store-badge.svg' /></a>

          </div>

        </div>

        <figure class="hero-banner">
          <img src={require('../assets/img/hero1.png')} width="720" height="673" alt="hero banner" class="w-100" />
        </figure>

      </div>
    </section>
  )
}

export default Homemain