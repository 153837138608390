import React, { useEffect, useState } from 'react'
import gpeapi from '../apis/gpeapi'

const Contact = () => {

  const [data,setdata] = useState([])

  useEffect(()=>{
    gpeapi.get('/getbasicdet').then((res)=>setdata(res.data.signupamt)).catch((e)=>console.log('error'))
  },[])

  const hanlecall = ()=>{
    window.open(`tel:+91${data.wpnumber}`)
  }

  const hanlewpcall = ()=>{
    window.open(`https://api.whatsapp.com/send/?phone=%2B91${data?.wpnumber}&text=Hello Kaamwao App&type=phone_number&app_absent=0`)
  }
  return (
    <section style={{marginTop:30}} class="section contact" id="contact" aria-label="contact">
        <div class="container">

          <h2 class="h2 section-title">Let's Contact With Us</h2>

          <p class="section-text">
          
          </p>

          <form  class="contact-form">

            <div class="input-wrapper">
              <input type="text" name="name" aria-label="name" placeholder="Your name*" required class="input-field"/>

              <input type="email" name="email_address" aria-label="email" placeholder="Email address*" required
                class="input-field"/>
            </div>

            <div class="input-wrapper">
              <input type="text" name="subject" aria-label="subject" placeholder="Subject" class="input-field"/>

              <input type="number" name="phone" aria-label="phone" placeholder="Phone number" class="input-field"/>
            </div>

            <textarea name="message" aria-label="message" placeholder="Your message...*" required
              class="input-field"></textarea>

            {/* <div class="checkbox-wrapper">
              <input type="checkbox" name="terms_and_policy" id="terms" required class="checkbox"/>

              <label for="terms" class="label">
                Accept <a href="#" class="label-link">Terms of Services</a> and <a href="#" class="label-link">Privacy
                  Policy</a>
              </label>
            </div> */}

            <button type="submit" class="btn btn-primary">Send Message</button>

          </form>

          <ul class="contact-list">

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Mail Here</h3>

                  <a href={`mailto:${data?.email}`} class="card-link">{data?.email}</a>
                  

                </div>

              </div>
            </li>

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon  name="location-outline" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Address</h3>

                  <a  class="card-link">63 Jalalpur </a>
                  <a  class="card-link">Gwalior MP 474005</a>


                </div>

              </div>
            </li>

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon name="headset-outline" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Call Here</h3>

                  <a onClick={hanlecall}  class="card-link">+91{data?.wpnumber}</a>

                  

                </div>

              </div>
            </li>

          </ul>

        </div>
      </section>
  )
}

export default Contact