import React, { useEffect, useState } from 'react'
import gpeapi from '../apis/gpeapi'
import Homemain from '../components/Homemain'
import Aboutus from './Aboutus'
import Clients from './Clients'
import Contact from './Contact'
import Modelss from './Modelss'
import Services from './Services'

const Home = () => {

  return (
    <div>
        <Homemain />
        <Services/>
        <Modelss/>
        <Aboutus/>
        {/* <Clients/> */}
        <Contact/>

    </div>
  )
}

export default Home