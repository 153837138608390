import React, { useEffect, useState } from 'react'
import gpeapi from '../apis/gpeapi'
import Cardview from '../components/Cardview'

const Services = () => {

    const [catedata, setcatedata] = useState([])
    useEffect(() => {
        gpeapi.get('/getcategory').then((res) => setcatedata(res.data.offerlist))
    }, [])
    return (
        <div style={{ marginTop: 30, backgroundColor: 'gray' }}>
            <section class="section service" id="service" aria-label="service">
                <div class="" style={{margin:'0px 10px'}}>

                    <h2 class="h2 section-title">Services We Provided</h2>

                    <p class="section-text">
                    All Types of Labours - Workers - Contractors - Service Agencies - Technicians
                    </p>


                    <div className='grid-list '>

                        {catedata && catedata.map((val, i) => (
                            <Cardview key={i} img={val.icon} text={val.name} hitext={val.hiname} />
                        ))}








                    </div>

                </div>
            </section>
        </div>
    )
}

export default Services