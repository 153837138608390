import React, { useEffect, useState } from 'react'
import gpeapi from '../apis/gpeapi'

const Footer = () => {

    const [data,setdata] = useState([])

    useEffect(()=>{
      gpeapi.get('/getbasicdet').then((res)=>setdata(res.data.signupamt)).catch((e)=>console.log('error'))
    },[])
  
  return (
    <footer class="footer" style={{backgroundColor:'black'}}>
    <div class="">
        <div class="row">
            <div class="footer-col">
                <h1 style={{color:'white'}}>{data?.appname}</h1>
                <p style={{color:'white'}}>by Gwalax Online Services</p>
                <p style={{color:'white'}}>
                Kaamwao app is Top App in India and we provide daily Labour or workers our aim to find workers digitaly in any area in india with 0% Commission ,
                Kaamwao is based in Gwalior Madhya Pradesh 
                </p>
            </div>
            {/* <div class="footer-col">
                <h4>Business Hours</h4>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Opining Days:</p>
                     <p style={{color:'white',fontSize:14}}>Monday - Friday :9AM to 6PM</p>
                     <p style={{color:'white',fontSize:14}}>Saturday : 9AM to 5PM</p>
                </div>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Vacations:</p>
                     <p style={{color:'white',fontSize:14}}>All Sunday Days</p>
                     <p style={{color:'white',fontSize:14}}>All Official Holidays</p>
                </div>
            </div> */}
            <div class="footer-col">
                <h4>Important Links</h4>
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-&-conditions">Terms & Conditions</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/refund-policy">Refund Policy</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4>follow us</h4>
                <div class="social-links">
                    <a target='_blank' href="https://www.facebook.com/kaamwao"><i class="fab fa-facebook-f"></i></a>
                    <a target='_blank' href="https://twitter.com/kaamwao"><i class="fab fa-twitter"></i></a>
                    <a target='_blank' href="https://www.instagram.com/kaamwao/"><i class="fab fa-instagram"></i></a>
                    <a  target='_blank' href="https://www.linkedin.com/company/kaamwao"><i class="fab fa-linkedin-in"></i></a>
                    <a target='_blank' href={`https://api.whatsapp.com/send/?phone=%2B91${data?.wpnumber}&text=Hello Kaamwao App&type=phone_number&app_absent=0`}><i class="fab fa-whatsapp"></i></a>
                    <a target='_blank' href="https://www.youtube.com/@kaamwao"><i class="fab fa-youtube"></i></a>
                    
                </div>
            </div>
        </div>

       
        <p style={{color:'white',marginTop:30}} class="copyright">
        &copy; <a href="/" class="copyright-link">{data?.appname} </a> 2023 All Rights Reserved by 
      </p>
    </div>
</footer>
  )
}

export default Footer