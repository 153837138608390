import React, { useEffect, useState } from 'react'
import gpeapi from '../apis/gpeapi'

const Privacypoliciy = () => {
  const [data,setdata] = useState([])

  useEffect(()=>{
    gpeapi.get('/getbasicdet').then((res)=>setdata(res.data.signupamt)).catch((e)=>console.log('error'))
  },[])
  return (
    <section style={{marginTop:30}} class="section contact" id="contact" aria-label="contact">
    <div class="container">

      <h2 class="h2 section-title">
Privacy Policy, Terms and Conditions,   Refund Policy</h2>

      <p class="section-text">
      
      </p>


      <h1>
Privacy Policy, Terms and Conditions,   Refund Policy for KaamWao</h1>

<p>Kaamwao is concerned about the privacy of its users (hereinafter referred to as "You" "Your" "Yourself") accessing its website located at https://kaamwao.com ("Website") and 'KaamWao' Mobile Application and has provided this Privacy Policy and Terms & Condition to familiarize You with the manner in which Kaamwao collects, uses and discloses Your information collected through the Website and App</p>

<p>KaamWao Owns and operate the ‘KaamWao’ App, which provides the Services.

If you choose to use our Service, then you agree to the collection and use of information in relation to this policy and Terms & Conditions. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>



<h2>INFORMATION COLLECTION AND USE:</h2>

<p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address, location, DOB, e-KYC (ID proofs) . The information that we collect will be used to contact or identify you.</p>

<h2>LOG DATA:</h2>

<p>We want to inform you that whenever you visit | use our Website, Mobile App, we collect information that your app sends to us which is called Log Data. This Log Data may include information such as your device details, photos that you share, and other statistics. We use the best security solutions offered in the market.</p>



<h2>SECURITY:</h2>

<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>



<h2>LINKS TO OTHER SITES:</h2>

<p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>


<h2>TERMS AND CONDITIONS:</h2>

<p>KaamWao App just connects workers/ labour/service professional/contractors with the Customers/Employers.

KaamWao app is Not responsible to any accidents, injuries, death, or damages ( for Persons and Property) occur during or before or after the work/Service. Customers/Employers are responsible if any unfortunate incidents / accidents happens and they will pay for the damages / hospital expenses, if any to the workers directly. As per Local Laws.</p>

<h2>REFUND POLICY:</h2>

<p>
If no work orders received or sent for plan duration,we will not refund subscription amount 
</p>

<h2>CHANGES TO OUR PRIVACY POLICY, TERMS AND CONDITIONS:</h2>

<p>If we decide to change our privacy policy, we will post those changes on this page.</p>

<h2>Our Advertising Partners</h2>

<p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

<ul>
    <li>
        <p>Google</p>
        <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
    </li>
</ul>

<h2>Advertising Partners Privacy Policies</h2>

<p>You may consult this list to find the Privacy Policy for each of the advertising partners of KaamWao.</p>

<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on KaamWao, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

<p>Note that KaamWao has no access to or control over these cookies that are used by third-party advertisers.</p>

<h2>CONTACT US:</h2>

<p>If there are any questions regarding this Privacy Policy, Terms and Conditions you may contact us at :

<b>{data?.email}</b></p>


      </div>
      </section>
  )
}

export default Privacypoliciy