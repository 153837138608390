import React from 'react'
import Cardview from '../components/Cardview'

const Modelss = () => {
  return (
    <div style={{marginTop:30}}>
    <section class="section service" id="service" aria-label="service">
        <div class="" style={{margin:'0px 10px'}}>

            <h2 class="h2 section-title">How it Works</h2>

            <p class="section-text">
            Hire Nearby Professional by Using These Three Steps
            </p>


            <div className='grid-list newgrid ' style={{margin:'10px 10px',padding:'25px 0px',backgroundColor:'#3c006b',borderRadius:10,alignItems:'center'}} >
               
               <div style={{display:'block',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,alignSelf:'center',margin:'auto'}} src={require('../assets/img/step1.png')}/>
                <h2 style={{color:'white'}}>Book Now</h2>
                <p style={{margin:'0px 90px',color:'white',fontSize:12}}>Book any Type of Service Providers</p>

               </div>

               <div style={{display:'block',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,alignSelf:'center',margin:'auto'}} src={require('../assets/img/step2.png')}/>
                <h2 style={{color:'white'}}>Partner Coming</h2>
                <p style={{margin:'0px 90px',color:'white',fontSize:12}}>Our Partner will be at your location soon</p>

               </div>

               <div style={{display:'block',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,alignSelf:'center',margin:'auto'}} src={require('../assets/img/step3.png')}/>
                <h2 style={{color:'white'}}>Enjoy</h2>
                <p style={{margin:'0px 90px',color:'white',fontSize:12}}>Boom! You'll never have to wait for Workers again</p>
               </div>



               
            </div>

        </div>
    </section>
</div>
  )
}

export default Modelss