import React from 'react'

const Aboutus = () => {
    return (
        <div style={{ marginTop: 30 }}>

            <section class="section service" id="service" aria-label="service">
                <div class="">

                    <h2 class="h2 section-title">About Us</h2>



                    <section class="section hero" id="home" aria-label="hero">
                        <div class="container">

                            <figure class="hero-banner">
                                <img src="https://ecomexpress.in/wp-content/uploads/2021/02/contact-us-banner-img.png" width="720" height="673" alt="hero banner" class="w-100" />
                            </figure>

                            <div class="hero-content">



                                <h1 class="h1 hero-title">About KaamWao</h1>

                                <p class="hero-text">
                                    Kaamwao App Owned by Gwalax Online Services

                                </p>
                                <p class="hero-text">
                                    Kaamwao is a India's TOP Workers Finder App

                                </p>

                                <p class="hero-text">
                                    find professionals for day-to-day work ends here at Kaamwao App. Kaamwao is a one-stop solution to find nearby skilled professionals based on their work and reviews. Book directly and make your deal. Get nearby plumbers, electricians, and technicians or more at your doorstep with 0% commission .
                                    Connects INSTANTLY with all Types of
                                    Daily Wage Labourers | Contractors | Technicians |  Service Agencies | Service Professionals.
                                </p>



                            </div>



                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}

export default Aboutus