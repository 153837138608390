import React from 'react'

const Cardview = ({img,text,subtext,differ,hitext}) => {
  return (
    <div  class="service-card" style={differ &&{backgroundColor:'#1b2959',borderRadius:25}}>

                <div class="card-icon" style={{backgroundColor:'white',border:' 1px solid #e23744'}} >
                  {/* <ion-icon name={img}></ion-icon> */}
                  <img style={{height:100,width:100,objectFit:'cover',borderRadius:100,border:'1px solid #e23744',padding:5}} src={img}/>
                </div>

                <h3 class="h3">
                  <p href="#" style={differ&&{color:'white'}} class="card-title">{text}</p>
                  <p href="#" style={{fontSize:14}} >( {hitext} )</p>
                </h3>

                <p style={differ&&{color:'white',lineHeight:1.5}} class="card-text">
                  {subtext}
                </p>

              </div>
  )
}

export default Cardview